<!--
 * @Descripttion:
 * @version:
 * @Author: 贾峰
 * @Date: 2019-11-12 22:45:24
 * @LastEditors: 贾峰
 * @LastEditTime: 2019-11-12 23:01:40
 -->
<template>
    <div class="mainpage">
        <p class="title">申请成功，请等待审核。。。。</p>
    </div>
</template>
<script>
export default {
}
</script>
<style  scoped lang="stylus">
.mainpage{
background-color: #438CC9
height 100vh
}
.title{
    height 100vh
    font-size: 0.4rem
    color: white
    display flex
    justify-content center
    align-items center
}
</style>
